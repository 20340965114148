<template>
    <div class="invoice-container" id="printArea">

        <table class="w-full">
            <tr>
                <td class="w-half">
                    <h1 class="text-left heading">Tax Invoice</h1>
                </td>
                <td class="w-half text-right logo-container">
                    <img src="../../assets/digital-hub-logo.png" alt="digitalhub logo" class="logo" width="300" />
                    <p class="logo-content">
                        <span>Powered by</span> <br>
                        DRAKE BUSINESS LOGISTICS <br>
                        A.B.N.	27 160 148 658
                    </p>
                </td>

            </tr>
        </table>

        <div class="margin-top">
            <table class="w-full">
                <tr>
                    <td class="w-half text-left" colspan="2">
                        <div>
                            <b>Invoice</b>#: {{ data.invoice_no ? data.invoice_no : '' }}
                        </div>
                        <div>
                            <b>Date</b>: {{ data.booked_date }}
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div class="margin-top">
            <table class="w-full">
                <tr>
                    <td class="w-half text-left" colspan="2">
                        <div>
                            <b>Sales person</b>: {{ data.requester ? data.requester.name : '' }}
                        </div>
                        <div>
                            <b>Site</b>: {{ data.mailroom_address ? `${data.mailroom_address} - ` : '' }} Mailroom {{
                                data.mailroom_branch }}
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div class="margin-top">
            <table class="postal">
                <thead>

                    <tr>
                        <th>QTY.</th>
                        <th>DESCRIPTION</th>
                        <th class="text-center">PRICE</th>
                        <th class="text-right">EXTENDED PRICE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="items">
                        <td>{{ data.requested_qty }}</td>
                        <td>{{ `${data.postal_document_type ? data.postal_document_type.postal_document_type : ''} ${data.reference_number ? '(' + data.reference_number + ')' : ''}` }}</td>
                        <td class="text-center">$ {{ data.unit_price.toFixed(2) }}</td>
                        <td class="text-right">$ {{ (data.unit_price * data.requested_qty).toFixed(2)  }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td rowspan="3">COMMENT</td>
                        <td colspan="2" class="text-right">
                            Total Amount. AUD
                        </td>
                        <td class="text-right">
                            $ {{ data.total_amount_aud.toFixed(2) }}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <div class="footer margin-top text-right">
            <div class="content">THANK YOU FOR YOUR BUSINESS !</div>
            <div class="logo">
                <img src="../../assets/drake-logo-new.png" alt="digitalhub logo" width="150" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PostalItemInvoice',
    props: {
        data: Object,
    },
};
</script>

<style>
.invoice-container {
    padding-block: 4rem;
    padding-inline: 2rem;

    background-color: white;
}

.heading {
    font-size: 35px;
    font-weight: 700;
}

h4 {
    margin: 0;
}

.w-full {
    width: 100%;
}

.w-half {
    width: 50%;
}

.margin-top {
    margin-top: 1.25rem;
}

.footer {
    font-size: 0.875rem;
    padding: 1rem;
    margin-top: 5rem;
}

.footer .logo {
    margin-top: 2rem;
}

.footer .content {
    font-weight: 600;
}

table {
    width: 100%;
    border-spacing: 0;
}

table tr.items td {
    padding: 0.5rem;
}

table.postal {
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
}

table.postal th,
table.postal td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}

.total {
    text-align: right;
    margin-top: 1rem;
    font-size: 0.875rem;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}
.logo-content {
    font-size: 15px;
    text-align: right;
}
.logo-content span {
    font-weight: 700;
    font-size: 14 !important;
}
.logo-container .logo {
    position: relative;
    right: -9% !important;
}
</style>
